import React from "react"
import "./category.css"
import { StaticImage } from "gatsby-plugin-image"
import "bootstrap/dist/css/bootstrap.min.css"
import { Link } from "gatsby"

const Category = () => {
  return (
    <>
      <div className="categorybgcolor  py-5 pb-5">
        <div className="container catgries-container ">
          <div className="heading">
            <h1>Most Popular</h1>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-3">
          <div
            className="card height-300 width-300 shadow-lg"
            style={{ height: "fit-content" }}
          >
            <StaticImage
              src="../images/medical-softwares.png"
              alt="Medical Software"
              placeholder="blurred"
              width={300}
            />
            <div className="card-body">
              <Link to="/medical-software" className="anchorlink mb-4">
                <h5 className="card-title">Medical Software</h5>
              </Link>
            </div>
          </div>
          <div
            className="card height-300 width-300 shadow-lg"
            style={{ height: "fit-content" }}
          >
            <StaticImage
              src="../images/pos-system.png"
              alt="POS system"
              placeholder="blurred"
              width={300}
            />
            <div className="card-body">
              <Link to="/pos-system" className="anchorlink mb-4">
                <h5 className="card-title">POS system</h5>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Category
