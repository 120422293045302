import * as React from "react"
import { useState, useEffect } from "react"

import Header from "../components/header"
import Contactform from "../components/form"
import JsonData from "../data/data.json"
import Mainfooter from "../components/mainfooter"
import Seo from "../components/seo"
import Detail from "../components/detail"
import Category from "../components/category"
import HowItWorks from "../components/howItWorks"

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles.css"

const IndexPage = () => {
  const [landingPageData, setLandingPageData] = useState({})
  console.log(landingPageData)
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  const pagebodystyle = {
    margin: 0,
    padding: 0,
    width: "100%",
    boxsizing: "border-box",
    overflow: "hidden",
  }

  return (
    <>
      <Seo title="Home" />
      <div className="page-body" style={pagebodystyle}>
        <Header />
        <HowItWorks />
        <Detail />
        <Category />
        <Mainfooter />
      </div>
    </>
  )
}

export default IndexPage
