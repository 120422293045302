import React from "react"
import "../howItWorks/index.css"
import icon1 from "../../images/icon(1).png"
import icon2 from "../../images/icon(2).png"
import icon3 from "../../images/icon(3).png"

const data = [
  {
    icon: icon1,
    heading: "Fill our form",
    description:
      "Tell us just a few basic details about what you’re looking to buy. Our quick forms will guide you through the key questions to consider.",
  },
  {
    icon: icon2,
    heading: "Get connected",
    description:
      "We’ll introduce you to a few, hand-selected sellers who will provide best price quotes and answer any questions you may have.",
  },
  {
    icon: icon3,
    heading: "Buy Smart",
    description:
      "You’ll compare multiple price quotes so you know you’re getting the best deal. Our handy Buyer’s Guides will prep you for the ins and outs.",
  },
]

const HowItWorks = () => {
  return (
    <section className="section1 pt-5 pb-3" title="How It Works">
      <div className="container">
        <div className="text-center">
          <h1>They easy way for Merchants</h1>
        </div>
        <div>
          <div
            className="row section-row px-2"
            style={{ backgroundColor: "rgb(246, 244, 244)" }}
          >
            {data.map(item => (
              <div
                className="col-lg-4 col-xl-4 col-md-6 mt-5 mb-md-5 section-col"
                style={{ backgroundColor: "rgb(246, 244, 244)" }}
              >
                <div className="row rounded shadow mx-3 bg-white px-1">
                  <div className="d-flex  p-3 justify-content-center">
                    <img src={item.icon} width="120" />
                  </div>

                  <div className="text-center">
                    <h5 className="card-title">{item.heading}</h5>
                    <div className="mt-3">
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
export default HowItWorks
