import React from "react"
import "./detail.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticImage } from "gatsby-plugin-image"

const Detail = () => {
  return (
    <>
      <div className=" ">
        <div className="detail-container">
          <div className="container py-2 mb-0">
            <div className="row">
              <div className="col-md-6 ">
                <div className="idea px-0 px-md-3">
                  <StaticImage
                    src="../images/business-idea.png"
                    alt="A dinosaur"
                    placeholder="blurred"
                    width={300}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="content">
                  <h1>We provide you Business ideas!</h1>
                  <p>
                    A business idea is a concept that can be used for financial
                    gain that is usually centered on a product or service that
                    can be offered for money. An idea is the first milestone in
                    the process of building a successful business
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="details pl-4">
          <div className="container">
            <div className="row mx-2 mx-md-5">
              <div className="col-md-6 order-md-2 d-flex align-items-center">
                <div>
                  <StaticImage
                    src="../images/img2.jpg"
                    alt="A dinosaur"
                    placeholder="blurred"
                    width={700}
                    height={500}
                  />
                </div>
              </div>

              <div className="col-md-6  d-flex align-items-center">
                <div className="mt-5 mt-md-0">
                  <h2>Make right decesion</h2>
                  <p className="mw-100">
                    Making the right business decesion at the right time is the
                    most important thing someone can give to it's business.
                    Superior Merchants helps you make those decesion. We get you
                    connected with right people so you can grow your businss.
                  </p>
                </div>
              </div>
            </div>

            <div className="row mt-4 sec-row">
              <div className="col-md-6 d-flex align-items-center">
                <StaticImage
                  src="../images/img3.jpg"
                  alt="A dinosaur"
                  placeholder="blurred"
                  width={700}
                />
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <div className="mt-5 mt-md-0">
                  <h2>Connect with the best</h2>
                  <p className="mw-100">
                    Getting connected to right buseinsses is very important and
                    Superior Merchnats helps you make those get connected with
                    right people and get best quotes. You can further filter out
                    leads based on your needs from our hand-picked vandors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Detail
