import * as React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticImage } from "gatsby-plugin-image"
import { MdKeyboardArrowRight } from "react-icons/md"
import logo from "../images/logo.png"

import "./header.css"
import { Link } from "gatsby"

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top  shadow p-3 mb-5 ">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={logo}
              alt=""
              className="d-inline-block align-text-top logo mb-0"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav nav-ul">
              <li className="nav-item">
                <Link
                  id="navlink1"
                  className="nav-link"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link id="navlink1" className="nav-link" to="/about-us">
                  About
                </Link>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle navlink5 "
                  to="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Categories
                </Link>
                <ul
                  className="dropdown-menu  catgriesdropdownmenu"
                  aria-labelledby="navbarDropdown"
                >
                  <li className="catgriessublinks">
                    <Link
                      className="dropdown-item catgriessubitem"
                      to="/medical-software"
                    >
                      <MdKeyboardArrowRight size="25px" />
                      Medical Software
                    </Link>
                  </li>
                  <li className="catgriessublinks">
                    <Link
                      className="dropdown-item catgriessubitem"
                      to="/pos-system"
                    >
                      <MdKeyboardArrowRight size="25px" />
                      POS system
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid banner-backgroundcolor py-5 my-10 ">
        <div className="container d-flex justify-content-between">
          <div className="banner-content">
            <h1 className="display-1">We Help You Grow</h1>
            <p className="lead max-w-650">
              Superior Merchants help businesses to grow by providing them all
              services under one roof. Taking all the husstle out and connecting
              you with the experts in the field to save your time and resources.
            </p>
            <div className="banner-btn">
              <Link className="btn btn-primary px-5 py-3" to="/categories">
                <p className="m-0 h5">All Services</p>
              </Link>
            </div>
          </div>

          <div className="bannerimg1">
            <StaticImage
              src="../images/shop.png"
              placeholder="blurred"
              layout="fullWidth"
              // width={400}
              // height={400}
              alt="A Store"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
